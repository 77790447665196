// scss-lint:disable ImportantRule
.block {
    &--bg {
        padding: $default-block-margin 0 ($default-block-margin - $default-margin);
        margin-bottom: $default-block-margin;
        overflow: hidden;

        &:last-child {
            margin-bottom: 0 !important;
        }

        &::before {
            position: absolute;
            left: -30%;
            bottom: 0;
            display: block;
            width: 90%;
            height: 110%;
            background: $color-black;
            content: '';
            transform: skew(-43deg, 0);
            z-index: -1;

            @include media-breakpoint-down(md) {
                width: 148%;
                left: -90%;
            }

            @include media-breakpoint-down(sm) {
                width: 150%;
                height: 100%;
                left: -160%;
                top: 0;
                bottom: initial;
            }
        }

        &--gray-dark {
            &::before {
                background: $color-gray-dark;
            }
        }

        &--gray-lighter {
            &::before {
                background: $color-gray-lighter;
            }
        }

        &--blue-darker {
            &::before {
                background: $color-blue-darker;
            }
        }

        &--black-light {
            &::before {
                background: $color-black-light;
            }
        }
    }

    &--bg + &--bg,
    &--bg + &--color-blue,
    &--bg + &--color-blue-dark,
    &--bg + &--color-black,
    &--bg + &--testimonial {
        margin-top: -$default-block-margin;
    }

    &--color-blue {
        padding: $default-block-margin 0 ($default-block-margin - $default-margin);
        background: $color-blue;
        margin-bottom: $default-block-margin;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &--color-blue + &--bg,
    &--color-blue + &--color-blue-dark,
    &--color-blue + &--color-black,
    &--color-blue + &--testimonial {
        margin-top: -$default-block-margin;
    }

    &--color-blue + &--color-blue {
        margin-top: -($default-block-margin + $default-block-margin);
    }

    &--color-blue-dark {
        padding: $default-block-margin 0 ($default-block-margin - $default-margin);
        background: $color-blue-darker;
        margin-bottom: $default-block-margin;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &--color-blue-dark + &--bg,
    &--color-blue-dark + &--color-blue,
    &--color-blue-dark + &--color-black,
    &--color-blue-dark + &--testimonial {
        margin-top: -$default-block-margin;
    }

    &--color-blue-dark + &--color-blue-dark {
        margin-top: -($default-block-margin + $default-block-margin);
    }

    &--color-black {
        padding: $default-block-margin 0 ($default-block-margin - $default-margin);
        background: $color-black;
        margin-bottom: $default-block-margin;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &--color-black + &--bg,
    &--color-black + &--color-blue,
    &--color-black + &--color-blue-dark,
    &--color-black + &--testimonial {
        margin-top: -$default-block-margin;
    }

    &--color-black + &--color-black {
        margin-top: -($default-block-margin + $default-block-margin);
    }
}
// scss-lint:enable ImportantRule
