.header {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    margin: 0 0 $default-margin;
    transition: all .3s ease-in-out;
    z-index: 999;
    background: $color-black-bg;

    + .block {
        margin-top: -$default-margin;
    }

    &__search {
        position: relative;
        display: block;
        width: 100%;
        padding: 15px;
        margin: -79px 0 0;
        background-color: $color-black;
        transition: all .3s ease-in-out;
        z-index: 1;

        .form-search {
            margin: 0;
        }

        .search-suggestions {
            position: absolute;
            display: none;
            top: 77px;
            left: 15px;
            right: 15px;
            background-color: $color-black;
            border: 1px solid $color-white;
            opacity: .9;
            margin: 0;

            &::after {
                position: absolute;
                left: 50%;
                top: 0;
                display: block;
                width: 16px;
                height: 16px;
                content: '';
                transform: rotate(45deg);
                border-top: 1px solid $color-white;
                border-left: 1px solid $color-white;
                background-color: $color-black;
                margin: -8px 0 0 -8px;
            }

            &.active {
                display: block;
            }

            li {
                display: block;
                border-bottom: 1px solid $color-white;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    display: block;
                    color: $color-white;
                    padding: 7px 15px;
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 5px 15px 6px;

        &-sales {
            justify-content: space-between;
            margin: 0 30px;
        }
    }

    .list {
        &--brand {
            order: 4;
        }
    }

    .logo {
        display: block;
        height: 21px;
        order: 2;
        margin: 0;

        @include media-breakpoint-down(md) {
            height: 25px;
            margin: 0 auto;
        }

        @include media-breakpoint-down(xs) {
            height: 16px;
        }

        img {
            width: auto;
            height: 100%;
            max-height: 100%;
            display: block;
        }
    }

    .navigation {
        &--header {
            order: 2;
        }
    }

    .nav-toggle {
        @include icon(close, transparent, 20px, 20px);
        display: none;
        width: 50px;
        height: 50px;
        padding: 7px;
        margin: 3px 10px 3px 0;
        transition: all .3s ease-in-out;
        order: 1;
        z-index: 5;
        position: relative;

        @include media-breakpoint-down(md) {
            display: block;
            margin: 0 10px 0 -15px;
        }

        @include media-breakpoint-down(xs) {
            width: 45px;
        }

        span,
        &::before,
        &::after {
            display: block;
            width: 30px;
            height: 4px;
            margin: 6px auto;
            content: '';
            background-color: $color-white;
            transition: all .3s;
            border-radius: 2px;
            backface-visibility: hidden;

            @include media-breakpoint-down(xs) {
                width: 25px;
            }
        }

        &:hover,
        &:focus {
            span,
            &::before,
            &::after {
                background-color: $color-red;
            }
        }

        &.active {
            @include icon(close, $color-red, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-white, 20px, 20px);
            }

            &::before {
                transform: rotate(45deg) translate(7px, 7px);
            }

            &::after {
                transform: rotate(-45deg) translate(7px, -7px);
            }

            span,
            &::before,
            &::after {
                opacity: 0;
            }
        }
    }

    .search-toggle,
    .language-toggle,
    .contact-toggle,
    .downloads-toggle {
        display: block;
        width: 40px;
        height: 50px;
        padding: 0;
        margin: 0;
        font-size: 0;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .search-toggle {
        @include icon(search, $color-white, 20px, 20px);
        order: 6;

        &:hover,
        &:focus {
            @include icon(search, $color-red, 20px, 20px);
        }

        &.active {
            @include icon(close, $color-white, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-red, 20px, 20px);
            }
        }
    }

    .language-toggle {
        @include icon(globe, $color-white, 20px, 20px);
        order: 5;

        &:hover,
        &:focus {
            @include icon(globe, $color-red, 20px, 20px);
        }
    }

    .contact-toggle {
        @include icon(contact, $color-white, 20px, 20px);
        order: 4;

        &:hover,
        &:focus {
            @include icon(contact, $color-red, 20px, 20px);
        }
    }

    .downloads-toggle {
        @include icon(downloads, $color-white, 20px, 20px);
        order: 3;

        &:hover,
        &:focus {
            @include icon(downloads, $color-red, 20px, 20px);
        }
    }

    .brands-toggle {
        position: relative;
        margin: 0 5px 0 0;
        order: 4;

        > button {
            position: relative;
            display: block;
            height: 50px;
            padding: 0 16px 0 34px;
            margin: 0 0 0 10px;
            color: $color-red;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 52px;
            transition: all .3s ease-in-out;

            @include media-breakpoint-down(xs) {
                font-size: 12px;
                padding: 0 14px 0 32px;
                margin-left: 5px;
            }

            &.automotive {
                @include icon(car, $color-red, 25px, 25px);
                background-position: center left;
            }

            &.decorative {
                @include icon(decorative, $color-red, 25px, 25px);
                background-position: center left;
            }

            &.industry {
                @include icon(industry, $color-red, 20px, 20px);
                background-position: center left;
            }

            &.bike-care {
                @include icon(bike, $color-red, 25px, 25px);
                background-position: center left;
            }

            &::after {
                @include icon(select, $color-red, 8px, 8px);
                position: absolute;
                right: 0;
                top: 50%;
                display: block;
                width: 8px;
                height: 8px;
                margin: -4px 0 0;
                content: '';
                transition: all .3s ease-in-out;
            }
        }

        ul {
            position: absolute;
            top: 100%;
            left: -99999px;
            margin: 0;
            padding: 5px 10px;
            background-color: $color-black-bg;

            li {
                a {
                    position: relative;
                    display: block;
                    height: 30px;
                    padding: 0 16px 0 34px;
                    margin: 0;
                    color: $color-white;
                    text-transform: uppercase;
                    font-weight: 600;
                    white-space: nowrap;
                    transition: all .3s ease-in-out;

                    @include media-breakpoint-down(xs) {
                        font-size: 12px;
                        padding: 0 14px 0 32px;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $color-red;
                    }
                }

                &.automotive {
                    a {
                        @include icon(car, $color-white, 22px, 22px);
                        background-position: center left;
                    }
                }

                &.decorative {
                    @include icon(decorative, $color-white, 25px, 25px);
                    background-position: center left;
                }

                &.industry {
                    a {
                        @include icon(industry, $color-white, 20px, 20px);
                        background-position: center left;
                    }
                }

                &.bike-care {
                    a {
                        @include icon(bike, $color-white, 25px, 25px);
                        background-position: center left;
                    }
                }

                &:hover,
                &:focus {
                    &.automotive {
                        a {
                            @include icon(car, $color-red, 22px, 22px);
                            background-position: center left;
                        }
                    }

                    &.decorative {
                        @include icon(decorative, $color-red, 25px, 25px);
                        background-position: center left;
                    }

                    &.industry {
                        a {
                            @include icon(industry, $color-red, 20px, 20px);
                            background-position: center left;
                        }
                    }

                    &.bike-care {
                        a {
                            @include icon(bike, $color-red, 25px, 25px);
                            background-position: center left;
                        }
                    }
                }
            }
        }

        &:hover,
        &.active {
            > button {
                &.automotive {
                    @include icon(car, $color-red, 25px, 25px);
                    background-position: center left;
                }

                &.decorative {
                    @include icon(decorative, $color-red, 25px, 25px);
                    background-position: center left;
                }

                &.industry {
                    @include icon(industry, $color-red, 20px, 20px);
                    background-position: center left;
                }

                &.bike-care {
                    @include icon(bike, $color-red, 25px, 25px);
                    background-position: center left;
                }

                &::after {
                    @include icon(select, $color-red, 8px, 8px);
                }
            }
        }

        &.active {
            > ul {
                left: 0;
            }
        }
    }
}

.scroll-up {
    .header {
        top: 0;
    }
}

.primary-search-active:not(.scroll-down) {
    .header {
        margin: 0 0 -47px;

        &__search {
            margin: 0;
        }
    }
}
