.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style: none;

    li {
        display: block;
    }

    &--categories {
        column-count: 2;

        @include media-breakpoint-down(sm) {
            column-count: unset;
        }

        li {
            &.active {
                font-weight: 600;
            }
        }
    }

    &--vcard {
        margin: 0 -20px;

        li {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            border-bottom: 1px solid $color-gray-3;
            padding: 12px 20px;

            &:last-of-type {
                border: 0;
            }

            a {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            span {
                font-size: 16px;
                line-height: 22px;
                color: $color-blue-dark;
            }

            .icon {
                width: 19px;
                height: 25px;
                flex: 0 0 19px;
                max-width: 19px;

                + span {
                    margin-left: auto;
                    flex: 0 0 91%;
                    max-width: 91%;
                }
            }
        }
    }

    &--office {
        display: block;
        border-bottom: 1px solid $color-white;
        max-width: 100%;
        padding-bottom: 25px;
        flex-grow: 1;
        width: 100%;

        @include media-breakpoint-down(sm) {
            width: 100%;
            min-width: unset;
        }

        li {
            display: flex;
            align-items: center;
            padding: 2px 0;

            span {
                + span {
                    margin-left: 5px;
                }
            }
        }
    }

    &--usps {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        li {
            @include media-breakpoint-down(md) {
                padding: 0 40px;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 15px;
            }
        }
    }

    &--sites {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 5px;
            margin: 0 0 10px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a:not(.btn) {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 7px 15px 7px 45px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: transparent;
                border-bottom: 1px solid $color-white;
                font-weight: 500;

                &::after {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    display: block;
                    width: 16px;
                    height: 11px;
                    content: '';
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            &.en {
                a {
                    &::after {
                        background: url('/assets/img/countries/gb.gif') no-repeat center center;
                    }
                }
            }

            &.de {
                a {
                    &::after {
                        background: url('/assets/img/countries/de.gif') no-repeat center center;
                    }
                }
            }

            &.sk {
                a {
                    &::after {
                        background: url('/assets/img/countries/sk.gif') no-repeat center center;
                        background-size: contain;
                    }
                }
            }

            &.nl {
                a {
                    &::after {
                        background: url('/assets/img/countries/nl.gif') no-repeat center center;
                    }
                }
            }

            &.cs {
                a {
                    &::after {
                        background: url('/assets/img/countries/cz.gif') no-repeat center center;
                    }
                }
            }

            &.es {
                a {
                    &::after {
                        background: url('/assets/img/countries/es.gif') no-repeat center center;
                    }
                }
            }

            &.it {
                a {
                    &::after {
                        background: url('/assets/img/countries/it.gif') no-repeat center center;
                    }
                }
            }

            &.fr {
                a {
                    &::after {
                        background: url('/assets/img/countries/fr.gif') no-repeat center center;
                    }
                }
            }

            &.pl {
                a {
                    &::after {
                        background: url('/assets/img/countries/pl.gif') no-repeat center center;
                    }
                }
            }

            &.hu {
                a {
                    &::after {
                        background: url('/assets/img/countries/hu.gif') no-repeat center center;
                    }
                }
            }

            &.da {
                a {
                    &::after {
                        background: url('/assets/img/countries/dk.gif') no-repeat center center;
                    }
                }
            }

            &.sv {
                a {
                    &::after {
                        background: url('/assets/img/countries/sv.gif') no-repeat center center;
                    }
                }
            }

            &.fi {
                a {
                    &::after {
                        background: url('/assets/img/countries/fi.gif') no-repeat center center;
                    }
                }
            }

            &.no {
                a {
                    &::after {
                        background: url('/assets/img/countries/no.gif') no-repeat center center;
                    }
                }
            }

            &.hr {
                a {
                    &::after {
                        background: url('/assets/img/countries/hr.svg') no-repeat center center;
                    }
                }
            }

            &.si {
                a {
                    &::after {
                        background: url('/assets/img/countries/si.svg') no-repeat center center;
                    }
                }
            }
        }
    }

    &--brand {
        margin: 0;

        @include media-breakpoint-down(md) {
            margin-right: -15px;
        }

        li {
            display: flex;
            flex-wrap: wrap;
            padding: 0 15px 0 0;
            margin: 0 5px 0 0;
            position: relative;

            &.active {
                .icon {
                    &--brand-site {
                        @include icon(brand-site, $color-red, 37px, 17px);
                    }
                }

                .toggle-brand {
                    @include icon(select, $color-red, 6px, 9px);
                    background-position: right center;
                }

                > ul {
                    right: 0;
                    display: block;
                }
            }

            &:hover,
            &:focus {
                .icon {
                    &--brand-site {
                        @include icon(brand-site, $color-red, 37px, 17px);
                    }
                }

                .toggle-brand {
                    @include icon(select, $color-red, 6px, 9px);
                    background-position: right center;
                }
            }

            .toggle-brand {
                @include icon(select, $color-white, 6px, 9px);
                width: 100%;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                background-position: right center;
            }

            > ul {
                position: absolute;
                right: -99999px;
                display: none;
                top: 50px;
                width: 110px;
                padding: 0 5px;
                background-color: $color-black-bg;

                li {
                    display: block;
                    margin: 0;
                    padding: 0;
                    text-align: right;
                }

                a {
                    color: $color-white;
                    display: block;
                    padding: 8px 15px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: normal;

                    &:hover,
                    &:focus {
                        color: $color-red;
                    }
                }
            }
        }
    }

    &--naw {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
            margin: 0 0 $default-margin;

            @include media-breakpoint-down(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a,
            button {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                color: $color-white;
                text-decoration: none;
                text-align: left;
                padding: 0;

                p {
                    flex: 1;
                    line-height: 20px;
                    margin: 0;
                }

                .icon {
                    flex: 0 0 30px;
                    max-width: 30px;
                    width: 30px;
                    height: 40px;
                    margin: 0 40px 0 0;

                    &--phone {
                        @include icon(phone, $color-white, 30x, 30px);
                    }

                    &--messenger {
                        @include icon(messenger, $color-white, 30px, 30px);
                    }

                    &--form {
                        @include icon(form, $color-white, 30px, 30px);
                    }

                    &--marker {
                        @include icon(marker, $color-white, 38px, 38px);
                    }

                    &--chat {
                        @include icon(chat, $color-white, 30px, 30px);
                    }

                    &--globe {
                        @include icon(globe-small, $color-white, 30px, 30px);
                    }
                }

                &:hover,
                &:focus {
                    color: $color-red;
                    text-decoration: none;

                    .icon {
                        &--phone {
                            @include icon(phone, $color-red, 30px, 30px);
                        }

                        &--messenger {
                            @include icon(messenger, $color-red, 30px, 30px);
                        }

                        &--form {
                            @include icon(form, $color-red, 30px, 30px);
                        }

                        &--marker {
                            @include icon(marker, $color-red, 38px, 38px);
                        }

                        &--chat {
                            @include icon(chat, $color-red, 30px, 30px);
                        }

                        &--globe {
                            @include icon(globe-small, $color-red, 30px, 30px);
                        }
                    }
                }
            }
        }
    }

    &--store {
        display: block;
        margin: 0 0 $default-margin;

        li {
            display: block;
            margin-bottom: 2px;

            a {
                color: $color-white;
                display: block;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $color-red;
                }
            }
        }
    }

    &--specs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        li {
            padding: 0 15px;
            margin: 0;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a,
            span {
                position: relative;
                display: block;
                width: 100%;
                color: $color-white;
                font-size: 16px;
                line-height: 26px;
                font-weight: 600;
                cursor: pointer;
                text-transform: uppercase;
            }

            ul {
                position: absolute;
                top: 100%;
                width: 160px;
                left: 50%;
                z-index: 9;
                display: none;
                padding: 0;
                margin: 14px 0 0;
                background: $color-red;
                transform: translateX(-50%);

                @include media-breakpoint-down(md) {
                    position: static;
                    width: 100%;
                    transform: none;
                    background: none;
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    top: -14px;
                    display: block;
                    width: 100%;
                    height: 14px;
                    content: '';

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    display: block;
                    width: 16px;
                    height: 16px;
                    margin: -8px 0 0 -8px;
                    content: '';
                    transform: rotate(-45deg);
                    background: $color-red;
                    z-index: -1;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                li {
                    padding: 0;

                    @include media-breakpoint-down(md) {
                        padding: 0 0 0 45px;
                    }

                    a,
                    span {
                        color: $color-white;
                        font-weight: 400;
                        text-transform: none;
                        padding: 10px 15px;

                        &:hover,
                        &:focus {
                            color: $color-white;
                            text-decoration: underline;

                            @include media-breakpoint-down(md) {
                                color: $color-red;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            &.has-specs {
                position: relative;

                > .toggle-specs {
                    @include icon(arrow, $color-white, 15px, 14px);
                    position: absolute;
                    top: 0;
                    right: 15px;
                    display: none;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    transform: rotate(90deg);
                    transition: transform .3s;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }

                &:hover,
                &:focus {
                    > .toggle-specs {
                        @include icon(arrow, $color-red, 15px, 14px);
                    }
                }

                &.active {
                    > .toggle-specs {
                        @include icon(arrow, $color-white, 15px, 14px);
                    }
                }

                &.active,
                &.specs-active {
                    > .toggle-specs {
                        transform: rotate(-90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.specs-active {
                    > .toggle-specs {
                        @include icon(arrow, $color-white, 14px, 14px);
                        transform: rotate(90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
                // scss-lint:enable MergeableSelector
            }
        }

        > li {
            margin: 0 0 $default-margin;

            > a,
            > span {
                padding: 7px 0 7px 60px;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    content: '';
                }
            }

            &.paint-pallet {
                > a,
                > span {
                    &::after {
                        @include icon(paint-pallet, $color-white, 40px, 40px);
                    }
                }
            }

            &.paint-color {
                > a,
                > span {
                    &::after {
                        @include icon(paint-color, $color-white, 40px, 40px);
                    }
                }
            }

            &.measure-cup {
                > a,
                > span {
                    &::after {
                        @include icon(measure-cup, $color-white, 40px, 40px);
                    }
                }
            }

            &:hover,
            &:focus {
                > a,
                > span {
                    color: $color-red;
                    text-decoration: none;
                }

                &.paint-pallet {
                    > a,
                    > span {
                        &::after {
                            @include icon(paint-pallet, $color-red, 40px, 40px);
                        }
                    }
                }

                &.paint-color {
                    > a,
                    > span {
                        &::after {
                            @include icon(paint-color, $color-red, 40px, 40px);
                        }
                    }
                }

                &.measure-cup {
                    > a,
                    > span {
                        &::after {
                            @include icon(measure-cup, $color-red, 40px, 40px);
                        }
                    }
                }

                ul {
                    display: block;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
    }

    &--tags {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 30px;

        li {
            margin: 0 10px 10px 0;

            &::before {
                display: none;
            }

            a {
                display: inline-block;
                color: $text-color;
                border: 0 solid;
                font-size: 14px;
                line-height: 28px;
                padding: 5px 20px;
                font-weight: 400;
                background-color: $black;
                text-decoration: none;
                opacity: 1;
                transition: opacity ease-in-out .3s;

                &:hover,
                &:focus {
                    color: $color-black;
                    text-decoration: none;
                    background-color: $color-white;
                    border-color: $color-white;
                    opacity: 1;
                }
            }
        }
    }

    &--footer {
        margin: 0 0 40px;

        li {
            display: block;

            a {
                display: block;
                font-size: 16px;
                line-height: 23px;
                color: $text-color;

                &:hover,
                &:focus {
                    color: $text-color;
                    text-decoration: underline;
                }
            }
        }
    }

    &--social-follow {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            justify-content: center;
            max-width: 100%;
            margin: 0 0 40px;
        }

        li {
            flex: 1;
            margin: 0 5px 10px 0;

            @include media-breakpoint-down(md) {
                flex: 0;
            }

            a {
                position: relative;
                display: block;
                width: 36px;
                height: 36px;
                font-size: 0;
                border-radius: 50%;
                background-color: transparent;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &::after {
                        @include icon(facebook, $color-white, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::after {
                        @include icon(twitter, $color-white, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::after {
                        @include icon(youtube, $color-white, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::after {
                        @include icon(linkedin, $color-white, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::after {
                        @include icon(instagram, $color-white, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::after {
                        @include icon(pinterest, $color-white, 20px, 20px);
                    }
                }
            }
        }
    }

    &--social-share {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0 0 8px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $text-color;
                font-size: 14px;
                line-height: 22px;
                padding: 5px 15px 5px 36px;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &::before {
                        @include icon(facebook, $text-color, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::before {
                        @include icon(twitter, $text-color, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::before {
                        @include icon(youtube, $text-color, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::before {
                        @include icon(linkedin, $text-color, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::before {
                        @include icon(instagram, $text-color, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::before {
                        @include icon(pinterest, $text-color, 20px, 20px);
                    }
                }
            }

            &.whatsapp {
                a {
                    &::before {
                        @include icon(whatsapp, $text-color, 20px, 20px);
                    }
                }
            }

            &.email {
                a {
                    &::before {
                        @include icon(email, $text-color, 20px, 20px);
                    }
                }
            }
        }
    }
}
