// Title block

.block {
    &--title {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.title {
    $self: &;
    display: flex;
    width: 80%;
    flex-direction: column;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    &__title {
        text-align: center;

        .is-h1 {
            + .is-h2 {
                margin-top: -$default-margin;
            }
        }

        .is-h2 {
            font-size: 52px;
            line-height: 62px;
        }

        .text-color {
            &--white {
                color: $color-white;
            }

            &--red {
                color: $color-red;
            }

            &--orange {
                color: $color-orange;
            }

            &--yellow {
                color: $color-yellow;
            }

            &--green {
                color: $color-green;
            }

            &--blue {
                color: $color-blue-light;
            }
        }
    }

    &__content {
        text-align: center;

        .richtext {
            font-size: 24px;
            line-height: 42px;
        }
    }

    &--large {
        #{ $self }__title {
            .is-h1 {
                font-size: 72px;
                line-height: 82px;

                + .is-h2 {
                    margin-top: -$default-margin;
                }
            }

            .is-h2 {
                font-size: 42px;
                line-height: 52px;
            }
        }
    }

    &--medium {
        #{ $self }__title {
            .is-h1 {
                font-size: 42px;
                line-height: 52px;

                + .is-h2 {
                    margin-top: -$default-margin;
                }
            }

            .is-h2 {
                font-size: 28px;
                line-height: 38px;
            }
        }
    }

    &--small {
        #{ $self }__title {
            .is-h1 {
                font-size: 21px;
                line-height: 26px;

                + .is-h2 {
                    margin-top: -$default-margin;
                }
            }

            .is-h2 {
                font-size: 16px;
                line-height: 32px;
            }
        }
    }
}
