// Image block

.block {
    &--image-block {
        margin: 0;
    }
}

.image-block {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;

    &__title {
        text-align: center;
    }

    &__image {
        position: relative;
        margin: 0 0 40px;

        a {
            display: block;

            &.video {
                position: relative;

                &::after {
                    @include icon(play, $color-white, 20px, 20px);
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    content: '';
                }

                &:hover,
                &:focus {
                    &::after {
                        @include icon(play, $color-pink, 20px, 20px);
                    }
                }
            }
        }
    }

    &__buttons {
        text-align: center;
    }
}
