// USPS block

.block {
    $self: &;

    &--usps {
        margin: 0;

        &#{ $self }--color-blue {
            .usp {
                color: $color-white;
            }
        }
    }
}

.usps {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;

        picture {
            margin: 0 0 $default-margin;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.usp {
    $self: &;
    display: flex;
    flex-flow: wrap row;
    align-items: center;
    color: $text-color;
    font-size: 21px;
    line-height: 26px;
    font-weight: 300;
    letter-spacing: 1.2px;
    margin: 0 0 $default-margin;

    .usp__image {
        flex: 0 0 auto;
        max-width: 30%;

        @include media-breakpoint-down(sm) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    .usp__content {
        flex: 1;
        padding: 0 30px;
        max-width: 100%;

        @include media-breakpoint-down(sm) {
            flex: 0 0 80%;
            max-width: 80%;
        }

        p {
            margin: 0;
        }
    }

    &[href] {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: underline;
        }
    }
}
