.breadcrumb {
    display: block;
    padding: 37px 36px 0;
    margin: -$default-margin 0 30px;
    background-color: transparent;
    border-radius: 0;
    z-index: 88;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &-list {
        display: flex;
        padding: 0;
        margin: 0;
        font-size: 13px;
    }

    &-item {
        color: $color-white;
        font-size: 12px;
        line-height: 23px;

        a {
            color: $color-white;
        }

        &.active {
            a {
                color: $color-white;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                padding-left: 3px;

                &::before {
                    content: '>';
                    padding-left: 3px;
                    color: $color-white;
                    font-size: 12px;
                    line-height: 23px;
                }
            }
        }
    }

    + .main {
        .block {
            &:first-child {
                margin-top: -90px;
                padding-top: 90px;

                &:not(.block--bg):not(.block--color-blue):not(.block--color-blue-dark):not(.block--color-black):not(.block--testimonial) {
                    margin-top: 0;
                    padding-top: 0;

                    @include media-breakpoint-down(md) {
                        margin-top: 0;
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
