// Testimonial block

.block {
    &--testimonial {
        margin: 0 0 $default-block-margin;

        &:last-child {
            margin: 0;
        }

        .wrapper {
            @include media-breakpoint-up(lg) {
                padding: 0;
                max-width: 1440px;
            }
        }
    }

    &--testimonial + &--color-blue,
    &--testimonial + &--color-blue-dark,
    &--testimonial + &--color-black,
    &--testimonial + &--color-testimonial {
        margin-top: -$default-block-margin;
    }
}

.testimonial {
    @include make-row();
    position: relative;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        margin: 0 -30px;
    }

    &__image {
        @include make-col(6.8);
        position: relative;
        overflow: hidden;
        order: 2;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            order: 1;
        }

        &::after {
            position: absolute;
            left: -680px;
            bottom: 0;
            display: block;
            width: 1000px;
            height: 1000px;
            content: '';
            background: $color-blue-darker;
            transform: skew(-32deg, 0);

            @include media-breakpoint-down(sm) {
                right: -680px;
                left: auto;
                top: 0;
                bottom: auto;
            }
        }

        picture {
            width: 100%;
            height: 100%;

            @include media-breakpoint-down(sm) {
                height: auto;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include media-breakpoint-down(sm) {
                    height: auto;
                    object-fit: unset;
                }
            }
        }
    }

    &__content {
        @include make-col(5.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0 0 70px;
        background: $color-blue-darker;
        order: 1;

        @include media-breakpoint-down(md) {
            @include make-col(6);
            padding: 30px 0 0 30px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding: 30px 30px 0;
            order: 2;
        }

        .is-h2 {
            color: $color-white;
            font-size: 52px;
            line-height: 72px;
            font-weight: 300;
            text-transform: none;

            @include media-breakpoint-down(md) {
                font-size: 42px;
                line-height: 52px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 32px;
                line-height: 42px;
            }

            + p {
                margin-top: -($default-margin / 2);
            }

            &::before {
                content: '“';
            }

            &::after {
                content: '”';
            }
        }

        p {
            font-weight: 600;
        }
    }
}
