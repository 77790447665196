.download-info {
    display: flex;
    flex-direction: column;
    max-width: 360px;

    .btn {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .btn-dropdown {
        width: 100%;
        margin: 0 0 $default-margin;
    }
}

.product-details {
    display: none;
}
