// scss-lint:disable ImportantRule
.block {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
}
// scss-lint:enable ImportantRule

.filter {
    $self: &;
    display: flex;
    flex-direction: column;

    &__title {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 0 ($default-block-margin - $default-margin);

        .is-h1,
        .is-h2 {
            flex: 1;
        }

        .form {
            .form-group {
                &:first-child:last-child {
                    .form-control {
                        min-width: 200px;
                    }
                }
                &:last-child {
                    max-width: 250px;
                }
            }
        }
    }

    &__content {
        margin: 0 0 ($default-block-margin - $default-margin);

        .is-h3 {
            &:first-child {
                margin-top: -($default-block-margin - ($default-margin / 2));
                font-size: 21px;
                line-height: 26px;
                font-weight: 400;
                text-transform: none;
            }
        }

        .form {
            .form-group {
                flex: 0 0 25%;
                max-width: 25%;

                @include media-breakpoint-down(md) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &--rows {
        .form {
            .form-group {
                max-width: 14.285%;

                @include media-breakpoint-down(lg) {
                    max-width: 16.667%;
                }

                @include media-breakpoint-down(md) {
                    max-width: 33.33%;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 50%;
                }

                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }

                &:first-of-type {
                    max-width: 16%;

                    @include media-breakpoint-down(lg) {
                        max-width: 16.667%;
                    }

                    @include media-breakpoint-down(md) {
                        max-width: 33.33%;
                    }

                    @include media-breakpoint-down(sm) {
                        max-width: 50%;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                    }
                }

                &:nth-of-type(2) {
                    max-width: 10%;

                    @include media-breakpoint-down(lg) {
                        max-width: 16.667%;
                    }

                    @include media-breakpoint-down(md) {
                        max-width: 33.33%;
                    }

                    @include media-breakpoint-down(sm) {
                        max-width: 50%;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                    }
                }

                &:nth-of-type(4) {
                    max-width: 13%;

                    @include media-breakpoint-down(lg) {
                        max-width: 16.667%;
                    }

                    @include media-breakpoint-down(md) {
                        max-width: 33.33%;
                    }

                    @include media-breakpoint-down(sm) {
                        max-width: 50%;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                    }
                }

                &:nth-of-type(6) {
                    max-width: 15%;

                    @include media-breakpoint-down(lg) {
                        max-width: 16.667%;
                    }

                    @include media-breakpoint-down(md) {
                        max-width: 33.33%;
                    }

                    @include media-breakpoint-down(sm) {
                        max-width: 50%;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                    }
                }

                &:last-child:not(:first-child) {
                    flex: auto;
                    max-width: 170px;

                    .form-control-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0 0 0 20px;
                        border-left: 2px solid $color-white;

                        @include media-breakpoint-down(lg) {
                            border-left: 0;
                            padding: 0;
                            justify-content: center;
                        }
                    }

                    .btn {
                        flex: 1;

                        @include media-breakpoint-down(lg) {
                            max-width: 150px;
                        }

                        @include media-breakpoint-down(xs) {
                            max-width: unset;
                            flex: 0 0 100%;
                            max-width: 100%;
                        }

                        &--reset {
                            flex: unset;
                        }

                        + .btn {
                            @include media-breakpoint-down(xs) {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .bootstrap-select {
                    &:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
                        width: 100%;
                    }

                    .dropdown-toggle {
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }
                }

                .btn {
                    font-size: 14px;

                    &--reset {
                        padding-left: 0;
                        padding-right: 0;
                        border: 0;
                        flex: unset;

                        &:hover,
                        &:focus {
                            background: transparent;
                            color: $color-red;
                        }
                    }
                }
            }

            .form-control {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }

            .form-control-wrapper {
                max-width: 100%;
            }
        }
    }
}

.products-overview {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    &__item {
        @include make-col(3);
        padding: 0 15px;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(lg) {
            @include make-col(4);
        }

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(xs) {
            @include make-col(12);
        }
    }
}

// Language suggestion bar

.language-message {
    position: static;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    padding: 10px 0 0;
    color: $text-color;
    background: $color-red;
    z-index: 99999;

    @include media-breakpoint-down(md) {
        display: block;
        padding-right: 30px;
    }

    &__content {
        padding: 2px 15px;
        font-weight: 600;
        margin: 0 0 10px;
        max-width: 65%;

        @include media-breakpoint-down(lg) {
            max-width: 50%;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        p {
            margin: 0;
        }
    }

    &__languages {
        display: flex;
        padding: 0 60px 0 10px;

        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }

        span {
            display: inline-block;
            padding: 2px 0;
            margin: 0 5px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .btn {
            font-size: 14px;
            text-align: left;
            margin: 0 0 10px;
            align-self: center;
            padding: 0 22px;
            border-color: $color-white;

            &--close {
                position: absolute;
                top: 12px;
                right: 15px;
                width: auto;
                height: 32px;
                font-size: 0;
                padding: 0;

                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }
        }
    }
}

// Language popup

.popup {
    position: fixed;
    display: none;
    z-index: 99999;

    &.active {
        display: block;
    }

    &::before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        opacity: .9;
        background: $color-black;
    }

    &__wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        display: block;
        width: 700px;
        max-width: 80%;
        color: $color-white;
        padding: 30px 40px 10px;
        transform: translate(-50%, -50%);
        background-color: $color-black;
        border: 0 solid;
        text-align: center;
        max-height: 90%;
        overflow: auto;

        @include media-breakpoint-down(sm) {
            padding: 50px 20px;
        }

        h2 {
            text-align: center;
            line-height: 38px;
            font-size: 28px;
            text-transform: uppercase;
            color: $color-white;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }

        .btn {
            &--close {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }
}

// Search

.search-results {
    &--list {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;

        &__item,
        &__empty-item {
            display: flex;
            flex-direction: column;
        }

        h2 {
            margin-bottom: 0;
            font-size: 42px;
            line-height: 44px;
        }

        .url {
            display: block;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 20px;
            font-style: italic;
        }
    }
}

.search-result {
    text-decoration: none;
    margin: 0 0 10px;

    &:hover,
    &:focus {
        color: $text-color;
        text-decoration: none;
    }

    &-item {
        display: flex;
        margin-bottom: $default-margin;
        color: $text-color;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        &__image {
            flex-shrink: 0;
            margin-right: $default-margin;
            width: 150px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin: 0 0 $default-margin;
                text-align: center;
            }
        }
    }
}

// Google maps

.maps {
    &--block {
        width: 100%;
        height: 500px;
        margin: -30px 0 30px;

        .map {
            width: 100%;
            height: 100%;
        }
    }
}

.blog {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
        margin-bottom: 60px;
    }
}

.article-block {
    $self: &;
    display: flex;
    flex-flow: wrap column;

    &__image {
        margin: 0 auto $default-margin;
    }

    &__title {
        flex: 0 0 55%;
        max-width: 55%;
        width: 55%;
        padding: 0 30px;
        text-align: left;
        margin: 0 auto $default-margin;

        @include media-breakpoint-down(lg) {
            flex: 0 0 75%;
            max-width: 75%;
            width: 75%;
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
            width: 100%;
            padding: 0 15px;
        }

        +#{ $self }__content {
            margin-top: -$default-margin;
        }
    }

    &__content {
        flex: 0 0 55%;
        max-width: 55%;
        width: 55%;
        padding: 0 30px;
        margin: 0 auto $default-margin;

        @include media-breakpoint-down(lg) {
            flex: 0 0 75%;
            max-width: 75%;
            width: 75%;
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
            width: 100%;
            padding: 0 15px;
        }
    }
}
