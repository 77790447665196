h1,
.is-h1 {
    font-family: $heading-font;
    color: $color-white;
    font-size: 72px;
    line-height: 72px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .32px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 42px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 32px;
    }

    .special {
        color: $color-white;
        font-size: 52px;
        font-weight: 300;
    }
}

h2,
.is-h2 {
    font-family: $heading-font;
    color: $color-white;
    font-size: 52px;
    line-height: 52px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .32px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 42px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 32px;
    }

    .special {
        color: $color-white;
        font-size: 32px;
        font-weight: 300;
    }
}

h3,
.is-h3 {
    font-family: $heading-font;
    color: $color-white;
    font-size: 42px;
    line-height: 47px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .32px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 42px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 32px;
    }

    .special {
        color: $color-white;
        font-size: 22px;
        font-weight: 300;
    }
}

h4,
.is-h4 {
    font-family: $heading-font;
    color: $color-white;
    font-size: 21px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .32px;
    margin: 0 0 $default-margin / 2;
}

h5,
.is-h5 {
    font-family: $heading-font;
    color: $color-white;
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .32px;
    margin: 0 0 $default-margin / 2;
}

h6,
.is-h6 {
    font-family: $heading-font;
    color: $color-white;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 $default-margin / 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    + .info {
        font-size: 14px;
        margin-top: -$default-margin;
    }
}
