// Dealers map block

.block {
    &--dealers-map {
        margin: 0 0 $default-margin;

        &:first-child {
            .dealers-map-block {
                .maps {
                    height: calc(100% + 90px);
                    margin-top: -90px;

                    @include media-breakpoint-down(sm) {
                        height: 100%;
                        margin-top: 0;
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.dealers-map-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100vh;

    @include media-breakpoint-down(sm) {
        height: auto;
    }

    &__search {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0 0 30%;
        max-width: 30%;
        padding: 0 20px;
        height: 100%;

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            height: 100vh;
        }

        .form {
            margin: 0 0 20px;

            .form-group {
                padding: 0;

                &:last-child {
                    flex: 0 0 140px;
                    max-width: 140px;
                }
            }
        }

        .dealers-map-overview {
            flex: 1;
            overflow: auto;
            padding: 0 15px 0 0;
        }

        .dealer-map {
            z-index: 0;

            .hidden {
                display: none;
            }

            &.active {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 60px 25px 20px;
                background-color: $color-white;
                z-index: 1;

                .hidden {
                    display: block;
                }

                .more-info {
                    @include icon(close, $color-black, 22px, 22px);
                    top: 60px;
                    right: 25px;
                    width: 22px;
                    height: 22px;
                }

                .directions {
                    top: 105px;
                    right: 20px;
                }
            }
        }
    }

    &__content {
        flex: 0 0 70%;
        max-width: 70%;

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            height: 100vh;
        }

        .maps {
            height: 100%;
            margin: 0;
            box-shadow: none;
            border-radius: 0;
            background-color: $color-gray;

            &.loading-location {
                position: relative;

                &::after {
                    content: '';
                    width: 180px;
                    height: 180px;
                    max-width: 100%;
                    max-height: 100%;
                    background: url('../../img/loading.svg') no-repeat center transparent;
                    position: absolute;
                    background-size: 180px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

.dealers-map-overview {
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;
}

.dealers-map-message {
    [data-maps-message] {
        display: none;
    }
}

.dealer-map {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $color-black;
    padding: 20px 20px 0;
    margin: 0 0 1px;
    background: $color-white;

    &__title {
        .is-h3 {
            color: $color-black;
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 20px;
        }

        + #{ $self }__content {
            margin-top: -20px;
        }
    }

    &__content {
        font-size: 16px;
        line-height: 24px;

        .is-h3 {
            color: $color-black;
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 20px;

            + p,
            + .list {
                margin-top: -20px;
            }
        }

        p,
        .list {
            margin: 0 0 20px;
        }

        .list {
            li {
                a {
                    color: $color-black;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $color-black;
                        text-decoration: underline;
                    }
                }

                &.email,
                &.website {
                    a {
                        font-weight: 600;
                    }
                }
            }

            + p {
                margin-top: -20px;
            }
        }
    }

    .more-info {
        @include icon(info, $color-black, 30px, 30px);
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        width: 30px;
        height: 30px;
        padding: 0;
        font-size: 0;
    }

    .directions {
        @include icon(directions, $color-black, 30px, 30px);
        background-size: 85%;
        position: absolute;
        top: 55px;
        right: 10px;
        display: block;
        width: 30px;
        height: 30px;
        padding: 0px;
        font-size: 0;
        border: 2px solid $color-black;
        border-radius: 50%;
    }
}
