// Highlights block

// scss-lint:disable ImportantRule
.block {
    &--highlights {
        margin: 0;
        padding-bottom: 0 !important;

        &--keynote {
            background-color: $color-gray-blue;
            padding: 80px !important;

            @include media-breakpoint-down(sm) {
                padding: 40px 20px !important
            }

            .highlights,
            .highlight {
                margin: 0;

                &__image {
                    margin: 0;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
// scss-lint:enable ImportantRule

.highlights {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.highlight {
    $self: &;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: $default-block-margin - $default-margin;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .date-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    &__image {
        @include make-col(6);
        margin-bottom: $default-margin;
        position: relative;

        @include media-breakpoint-down(sm) {
            @include make-col(12);

            img {
                max-height: 350px;
            }
        }
    }

    &__content {
        @include make-col(6);
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 55px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .richtext {
            margin: 0 0 30px;

            + .read-more {
                margin-top: -30px;
            }
        }
    }

    &--reversed {
        flex-direction: row-reverse;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        #{ $self }__content {
            padding: 0 50px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }
        }
    }

    &--hero {
        @include make-row();
        align-items: initial;
        margin: 0 0 ($default-block-margin - $default-margin);

        @include media-breakpoint-down(sm) {
            margin: 0 -30px ($default-block-margin - $default-margin);
        }

        #{ $self }__image {
            @include make-col(8);
            position: relative;
            overflow: hidden;
            margin: 0 auto 0 0;
            order: 1;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            &::after {
                position: absolute;
                right: -680px;
                top: 0;
                display: block;
                width: 1000px;
                height: 1000px;
                content: '';
                background: $color-red;
                transform: skew(-32deg, 0);
            }

            picture {
                height: 100%;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        #{ $self }__content {
            @include make-col(4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 80px 0 10px;
            background: $color-red;
            margin: 0 0 0 auto;
            order: 2;

            @include media-breakpoint-down(md) {
                @include make-col(6);
                padding: 20px 15px 0 0;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 30px 30px 0;
            }

            .is-h2 {
                + .richtext {
                    margin-top: -($default-margin / 2);
                }
            }

            .richtext {
                color: $color-white;
                font-size: 16px;

                + p {
                    margin-top: -($default-margin / 2);
                }
            }
        }

        &#{ $self }--reversed {
            #{ $self }__image {
                order: 1;
                margin: 0 0 0 auto;

                &::after {
                    left: -680px;
                    right: auto;
                    top: auto;
                    bottom: 0;
                }

                a {
                    &.video {
                        &::after {
                            left: 65%;
                        }
                    }
                }
            }

            #{ $self }__content {
                padding: 20px 10px 0 80px;
                order: 2;
                margin: 0 auto 0 0;

                @include media-breakpoint-down(md) {
                    padding: 20px 0 0 15px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 30px 30px 0;
                }
            }
        }
    }

    &--subnav {
        padding: 0 0 0 15%;
        border-radius: 0;
        box-shadow: unset;
        margin: 0;
        display: flex;
        flex-flow: wrap row;
        align-items: center;

        #{ $self }__image {
            flex: 0 0 57.85%;
            max-width: 269px;

            + #{ $self }__content {
                margin-top: 0;
            }
        }

        #{ $self }__content {
            flex: 0 0 42.15%;
            max-width: 42.15%;
            padding: 0 15px;
            margin: 0;

            .is-h3 {
                font-size: 34px;
                line-height: 34px;
                color: $color-black;
            }

            .btn {
                border-color: $color-black;
                color: $color-black;
                font-size: 21px;
                line-height: normal;
                padding: 4px 21px;
                display: inline-block;

                &:hover,
                &:focus {
                    background: $color-black;
                    color: $color-white;
                }
            }
        }
    }

    &--small {
        position: relative;
        flex-direction: column;

        #{ $self }__image {
            @include make-col(12);
            margin: 0;
            overflow: hidden;

            picture {
                transform: scale(1);
                transition: transform .3s ease-in-out;
            }

            + #{ $self }__content {
                margin-top: 0;
            }
        }

        #{ $self }__content {
            @include make-col(12);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 0 20px;
            margin: 0;
            background: transparent;

            .is-h4 {
                color: $color-white;
            }
        }

        &[href] {
            &:hover,
            &:focus {
                #{ $self }__image {
                    picture {
                        transform: scale(1.1);
                    }
                }
            }

            &[target] {
                #{ $self }__content {
                    .is-h4 {
                        position: relative;
                        padding: 0 24px 0 0;

                        &::after {
                            @include icon(link, $color-white, 14px, 14px);
                            position: absolute;
                            top: 50%;
                            right: 0;
                            display: block;
                            width: 14px;
                            height: 14px;
                            margin: -7px 0 0;
                            content: '';
                        }
                    }
                }
            }
        }
    }

    &--simple {
        flex-direction: column;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        #{ $self }__content {
            @include make-col(12);
            align-self: normal;
            margin-left: 0;
            padding: 0;
            margin-bottom: 0;
            background: transparent;

            p {
                color: $text-color;
            }
        }

        #{ $self }__image {
            @include make-col(12);
            margin: 0 0 $default-margin;

            + #{ $self }__content {
                margin-top: -$default-margin / 2;
            }
        }
    }

    &--store {
        flex-direction: column;

        #{ $self }__image {
            @include make-col(12);
        }

        #{ $self }__content {
            @include make-col(12);
            padding: 0 10px;

            .is-h4 {
                + p {
                    margin-top: -$default-margin;
                }
            }
        }
    }

    &--row {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: initial;
        background-color: $color-black;

        #{ $self }__image {
            @include make-col(6);
            margin: 0 auto 0 0;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            picture {
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        #{ $self }__content {
            @include make-col(6);
            display: flex;
            flex-direction: column;
            margin: 0 0 0 auto;
            padding: 30px 30px 0;

            @include media-breakpoint-down(md) {
                @include make-col(12);
                padding: 30px 15px 0;
            }

            .is-h3 {
                a {
                    color: $color-white;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $color-red;
                        text-decoration: none;
                    }
                }
            }

            .tags {
                margin: auto 0 10px;

                .list {
                    &--tags {
                        margin: 0;

                        li a {
                            color: $text-color;
                            border: 1px solid $color-white;
                            background-color: transparent;
                            opacity: .5;
                            transition: opacity ease-in-out .3s;

                            &:hover,
                            &:focus {
                                color: $color-white;
                                background-color: transparent;
                                border-color: $color-white;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    &--text {
        align-items: initial;
        flex-direction: column;

        #{ $self }__content {
            @include make-col(12);
            padding: 0;
        }
    }

    &--red {
        #{ $self }__image {
            &::after {
                background: $color-red;
            }
        }

        #{ $self }__content {
            background: $color-red;
        }
    }

    &--green {
        #{ $self }__image {
            &::after {
                background: $color-green;
            }
        }

        #{ $self }__content {
            background: $color-green;
        }
    }

    &--blue {
        #{ $self }__image {
            &::after {
                background: $color-blue-light;
            }
        }

        #{ $self }__content {
            background: $color-blue-light;
        }
    }

    &--yellow {
        #{ $self }__image {
            &::after {
                background: $color-yellow;
            }
        }

        #{ $self }__content {
            background: $color-yellow;
        }
    }

    &--orange {
        #{ $self }__image {
            &::after {
                background: $color-orange;
            }
        }

        #{ $self }__content {
            background: $color-orange;
        }
    }
}

.highlights-overview {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    &__item {
        @include make-col(6);
        padding: 0 30px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &--row {
        #{ $self }__item {
            @include make-col(12);
        }
    }

    &--three-columns {
        #{ $self }__item {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--stores {
        margin: 0 -15px;

        #{ $self }__item {
            @include make-col(4);
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--two-columns,
    &--three-columns {
        #{ $self }__item {
            margin: 0 0 ($default-block-margin - $default-margin);

            .highlight:not(.highlight--small) {
                height: 100%;
                margin: 0;

                .richtext {
                    + p {
                        margin-top: auto;
                    }
                }
            }
        }
    }
}


.sales {
    .highlights-overview__item {
        padding: 0;

        @include media-breakpoint-down(sm) {
            padding: 0 30px;
        }
    }
}