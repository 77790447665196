$primary-color: #091353;
:root {
    --highlight-color: #1278B4;
    --primary-color: #091353;
    --form-control-disabled: #eee;
    --form-background: #fff;
    --mute-color: #DADBE5;
}

.w-5 {
    width: 5%;
}
.w-10 {
    width: 10%;
}
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-35 {
    width: 35%;
}
.w-40 {
    width: 40%;
}

.d-flex {
    display: flex;
}

.flex-grow-1 {
    flex-grow: 1;
}
.position-relative {
    position: relative;
}

.space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.border-bottom-1 {
    border-bottom: 1px solid var(--mute-color);
}

td {
    padding: 10px 0;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.field-sizing-content {
    field-sizing: content;
}

.sds-widget-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    .sds-search-form {
        display: flex;
        gap: 15px;
        align-items: flex-start;
        justify-content: space-between;
        background-color: rgba(9, 19, 83, 0.15);
        padding: 21px;
        border-radius: 30px;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        label {
            margin: 0;

            @include media-breakpoint-down(lg) {
                width: 100%
            }

            span {
                padding-top: 7px;

                @include media-breakpoint-down(lg) {
                    width: 80px;
                    display: inline-block;
                }
            }
        }

        .input {
            border: none;
            border-radius: 30px;
            padding: 10px 20px;
            margin-left: 20px;
            color: var(--primary-color);

            @include media-breakpoint-down(lg) {
                flex-grow: 1;
            }

            &:focus,
            &:focus-visible {
                border: none;
                outline: none;
            }
        }

        select.input {
            padding: 10px 30px 10px 15px;
            @include icon(chevron-expand, $primary-color, 8px, 8px);
            background-repeat: no-repeat;
            background-position: right 0.3rem top 50%;
            background-size: 1rem auto;
            cursor: pointer;
        }

        .action-button {
            position: absolute;
            right: 2px;
            bottom: 3px;
            padding: 0;
        }
    }

    .checkbox,
    input[type="checkbox"] {
        appearance: none;
        background-color: var(--form-background);
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid currentColor;
        border-radius: 0.15em;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
    }

    .checkbox-item {
        margin: 0 10px;
        cursor: pointer;
    }

    .checkbox::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--highlight-color);
        /* Windows High Contrast Mode */
        background-color: CanvasText;
    }

    .checkbox.checked.mixed::before {
        clip-path: polygon(10% 40%, 90% 40%, 90% 60%, 10% 60%);
    }

    .checkbox.checked::before{
        transform: scale(1);
    }

    .checkbox:focus {
        box-shadow: var(--highlight-color) 0 0 8px;
    }

    .fixed-max-width {
        max-width: 460px;
        word-wrap: break-word;
        padding: 0 10px;
    }

    .toggle-title {
        max-width: 460px;
        padding-right: 15px;

        .filename {
            overflow-wrap: break-word;
            cursor: pointer;
        }

        .file-title {
            display: none;
            font-size: 14px;
        }

        &.show-title {
            .file-title {
                display: block;
            }
        }
    }

    .btn.btn-secondary {
        background-color: var(--form-background);
        color: var(--primary-color);
        padding: 10px;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
            color: initial;
            border-color: initial;
            background-color: var(--mute-color);
        }
    }

    .dropdown-menu {
        top: calc(100% - 10px);
        background-color: var(--mute-color);
        border: none;

        a.dropdown-item {
            color: var(--primary-color);
        }
    }

    .basket-has-items {
        cursor: no-drop;
        bottom: 3rem;
        right: 3rem;

        img {
            filter: grayscale(1);
        }
    }

    .download-panel-toggle {
        position: fixed;
        background-color: var(--highlight-color);
        color: var(--form-background);
        border-radius: 19px;
        padding: 3px 15px;
        font-size: 14px;
        font-weight: bold;
        right: 2rem;
        bottom: 2rem;
        z-index: 1000;

        .opened {
            display: none;
        }

        .closed {
            display: inline;
        }

        &.open {
            .opened {
                display: inline;
            }

            .closed {
                display: none;
            }
        }

        .badge {
            background-color: var(--primary-color);
            padding: 1px;
            border-radius: 100px;
            min-width: 30px;
            position: absolute;
            top: -15px;
        }
    }

    .instruction {
        position: fixed;
        background-color: var(--highlight-color);
        color: var(--form-background);
        border-radius: 19px;
        padding: 3px 15px;
        font-size: 14px;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
        z-index: 1000;
    }

    #basket-download {
        position: fixed;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        bottom: 5rem;
        right: 1rem;
        z-index: 10000;
        background: none;
        padding: 0;
        border-radius: 30px;
        box-shadow: #00000033 0 0 20px;

        &.show {
            display: flex;
        }

        .download-header {
            background-color: var(--highlight-color);
            color: var(--form-background);
            text-align: center;
            border-radius: 30px 30px 0 0;
            font-size: 26px;
            padding: 20px 20px;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: bold;
            flex-grow: 1;
            width: 100%;
        }

        .download-list {
            background-color: var(--form-background);
            color: var(--primary-color);
            padding: 20px;
            width: 100%;

            .title {
                font-weight: bold;
            }

            .basket-file-list {
                margin: 0;
            }
        }

        .summary {
            display: flex;
            background-color: var(--form-background);
            color: var(--primary-color);
            padding: 10px 20px;
            justify-content: space-between;
            flex-grow: 1;
            width: 100%;
            gap: 30px;

            .clear-selection {
                background: none;
                text-transform: none;
                color: var(--highlight-color);
                border: none;

                &:hover {
                    background: none;
                }
            }
        }

        .action-bar {
            display: flex;
            padding: 20px 20px 30px;
            background-color: var(--form-background);
            color: var(--primary-color);
            justify-content: space-between;
            flex-grow: 1;
            width: 100%;
            border-radius: 0 0 30px 30px;
            gap: 10px;
            align-items: center;

            .download-format {
                border: none;
                border-radius: 30px;
                padding: 10px 20px;
                background-color: var(--mute-color);

                :focus,
                :focus-visible {
                    border: none;
                    outline: none;
                }
            }
        }

        p {
            margin: 0;
        }

        .btn {
            font-weight: bold;
            margin-left: 0;
            font-size: 14px;
            line-height: 16px;
            padding: 7px 12px;
            background-color: var(--highlight-color);
            border-color: var(--highlight-color);

            &:hover {
                background-color: var(--highlight-color);
                border-color: var(--highlight-color);
            }

            &.btn-basket-download {
                margin: 0 0 0 auto;
            }
        }
    }

    .actions {
        display: flex;
        min-width: 70px;
        justify-content: space-between;
    }
}
