// Content block

// scss-lint:disable ImportantRule
.block {
    &--content-block {
        margin: 0;
        padding-bottom: 0 !important;

        &__small {
            .content-block {
                max-width: 650px;
            }
        }
    }
}
// scss-lint:enable ImportantRule

.content-block {
    $self: &;
    display: flex;
    flex-direction: column;
    width: calc(66.6666666667% + 30px);
    margin: 0 auto ($default-block-margin - $default-margin);

    @include media-breakpoint-down(md) {
        width: auto;
        margin: 0 0 ($default-block-margin - $default-margin);
    }

    &__title {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }

    &--full {
        width: auto;
        margin: 0 0 ($default-block-margin - $default-margin);

        #{ $self }__title {
            .is-h2 {
                + .is-h4 {
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: 400;
                    margin: -$default-margin 0 $default-margin;
                }
            }
        }
    }

    &--two-columns {
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: -25px;
        margin-right: -25px;

        @include media-breakpoint-down(md) {
            margin-left: -15px;
            margin-right: -15px;
        }

        #{ $self }__title {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 25px;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 15px;
            }
        }

        #{ $self }__content {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 25px;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 15px;
            }
        }
    }
}

.sales {
    .block {
        margin: 40px 0;
    }
}