.block {
    &--article {
        $self: &;
        margin: 0 0 $default-block-margin;
    }

    &--article + &--highlight {
        margin-top: -20px;
    }

    &--blog + &--tags {
        margin-top: -$default-block-margin;
    }
}

.article {
    $self: &;

    &__title {
        width: 55%;
        display: flex;
        justify-content: center;
        margin: 0 auto $default-margin;
        flex-direction: column;
        padding: 0 0 0 30px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 75%;
            max-width: 75%;
            width: 75%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 0 15px;
        }

        .is-h3 {
            margin-bottom: 10px;
            display: block;
            text-align: left;
            font-size: 52px;
            line-height: 52px;
        }

        .info-publish {
            display: block;
            text-align: left;
            margin-top: -10px;
            font-size: 14px;
            line-height: 28px;
        }

        + #{ $self }__tags {
            @include make-col(5);
            margin: 0;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                margin: 0;
            }
        }
    }

    &__extra {
        width: 55%;
        margin: 0 auto $default-margin;
        padding: 0 30px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 75%;
            max-width: 75%;
            width: 75%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 0 15px;
        }
    }

    &__content {
        width: 55%;
        display: flex;
        flex-direction: column;
        margin: 0 auto $default-margin;
        padding: 0 30px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 75%;
            max-width: 75%;
            width: 75%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 0 15px;
        }

        .richtext {
            margin: 0;
        }
    }

    &__tags {
        @include make-col(8);
        display: flex;
        justify-content: flex-start;
        margin: 0 auto 20px;
        flex-direction: column;
        padding: 40px 0 0;
        border-top: 1px solid rgba($color-white, .5);

        @include media-breakpoint-down(md) {
            @include make-col(12);
            width: 100%;
        }

        .form {
            align-self: flex-end;
            min-width: 57%;
            margin: 0;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            select {
                &.form-control {
                    &:not([size]):not([multiple]) {
                        height: 40px;
                    }
                }
            }

            .form-control {
                &--select {
                    line-height: 23px;
                    padding: 6px 10px;
                }
            }
        }
    }

    &__text {
        @include make-col(12);
        margin: 0 auto;
    }

    &--overview {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        #{ $self }__title {
            @include make-col(7);
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(md) {
                @include make-col(7);
                margin: 0;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                margin: 0;
            }
        }

        #{ $self }__tags {
            border: 0 solid;
            padding-top: 0;
        }
    }
}
