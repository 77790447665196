// Bootstrap settings
$container-max-widths: (
    'sm': 540px,
    'md': 720px,
    'lg': 960px,
    'xl': 1170px
) !default;

// Lightcase settings
$lightcase-custom: (
    'font-path': '/assets/fonts/',

    'breakpoint': 768px
) !default;

// Overall
$default-margin: 30px;
$default-block-margin: 65px;
$heading-font: 'geomanist', sans-serif;
$text-font: 'geomanist', sans-serif;

$text-size: 16px;
$line-height: 32px;
$text-color: #fff;

$color-white: #fff;
$color-black: #000;
$color-black-off: #171717;
$color-black-bg: #1d1e1d;
$color-blue: #1b3a4b;
$color-blue-light: #006eab;
$color-blue-dark: #0a2c3f;
$color-red: #e63027;
$color-orange: #f7a600;
$color-yellow: #ffed00;
$color-green: #00963f;
$color-brown: #3e341f;
$color-gray: #707070;
$color-gray-light: #d1d1d1;
$color-gray-darker: #717171;

$color-gray-dark: #686969;
$color-gray-lighter: #a0a0a0;
$color-gray-blue: #dadce5;
$color-blue-darker: #033146;
$color-black-light: #262726;

$color-pink: #ed1164;
$color-black-off: #333;
$color-black-light: #666;
$color-gray-1: #f5f7f8;
$color-gray-2: #eaeaea;
$color-gray-3: #f7f7f7;

$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;
