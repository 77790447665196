// How to block

.block {
    &--howtos {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.howtos {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__slider {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.howto {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    &__image {
        position: relative;

        @include media-breakpoint-down(sm) {
            max-height: 420px;
        }

        img {
            @include media-breakpoint-down(xs) {
                object-fit: contain;
            }
        }

        .btn {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 14px;
        }
    }

    &__content {
        flex: 1;
        padding: 20px 20px 0;
        background: $color-black;
    }
}

.howtos-slider {
    display: flex;
    flex-direction: row;
    margin: 0 0 $default-margin;

    &__item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 10px;
        margin: 0;

        @include media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 75%;
            max-width: 75%;
        }

        .howto {
            height: 100%;
            margin: 0;
        }
    }
}

.swiper {
    &--howtos-slider {
        overflow: initial;
    }
}
