.footer {
    position: relative;
    flex-shrink: 0;
    background-color: $color-black;

    &.sales {
        padding: 0;
    }
    
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 14px;
        line-height: 28px;
        padding: 22px 30px 12px;

        @include media-breakpoint-down(md) {
            align-items: center;
            justify-content: center;
            padding: 50px 20px 30px;
        }

        &-sales {
            justify-content: space-between;
            align-items: center;
            padding: 30px 30px 20px;
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }
    }

    .copyright {
        display: inline-block;
        margin: 0 15px 5px 0;
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            margin: 0 15px 44px 0;
        }
    }

    .navigation {
        &--footer {
            display: inline-block;
            margin: 0 0 10px auto;

            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }
    }

    .logo {
        margin-bottom: 10px;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
            margin: 0 0 50px;
        }
    }

    .list {
        &--social-follow {
            li {
                &.facebook {
                    a {
                        &::after {
                            @include icon(facebook, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-facebook;
                        }
                    }
                }

                &.twitter {
                    a {
                        &::after {
                            @include icon(x, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-twitter;
                        }
                    }
                }

                &.youtube {
                    a {
                        &::after {
                            @include icon(youtube, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-youtube;
                        }
                    }
                }

                &.linkedin {
                    a {
                        &::after {
                            @include icon(linkedin, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-linkedin;
                        }
                    }
                }

                &.instagram {
                    a {
                        &::after {
                            @include icon(instagram, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-instagram;
                        }
                    }
                }

                &.pinterest {
                    a {
                        &::after {
                            @include icon(pinterest, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-pinterest;
                        }
                    }
                }
            }
        }
    }

    .social-share {
        margin: 0 0 10px 15px;

        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0 0 20px;
        }
    }
}
