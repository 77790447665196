// Anchor block

.block {
    &--anchor {
        position: sticky;
        top: 61px;
        width: 100%;
        margin: 0 0 $default-margin;
        z-index: 998;
        background: $color-black;
        border-bottom: 1px solid $color-white;

        + .breadcrumb {
            margin-top: -$default-margin;
        }
    }
}

.anchor {
    position: relative;
    display: flex;
    flex-direction: column;

    &__content {
        padding: 10px 20px 0;

        .navigation {
            &--anchor {
                margin: 0;
            }
        }
    }
}
