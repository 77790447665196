// Gallery block

.block {
    &--gallery {
        margin: 0 0 $default-block-margin - $default-margin;
    }
}

.gallery {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.gallery-overview {
    $self: &;
    margin: 0 -10px ($default-margin - 20px);
    overflow: hidden;

    &__item {
        float: left;
        width: 25%;
        padding: 0 10px;
        margin: 0 0 20px;

        @include media-breakpoint-down(md) {
            width: 50%;
        }

        &:first-child {
            float: right;
            width: 50%;

            @include media-breakpoint-down(md) {
                float: none;
                clear: both;
                width: 100%;
            }
        }

        &:nth-child(5) {
            ~ #{ $self }__item {
                display: none;
            }
        }

        picture {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .orbitvu-viewer {
            position: relative;

            picture {
                width: 100%;
                height: auto;
            }

            .orbitvu-viewer-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
