.block {
    &--taggbox {
        margin-bottom: $default-block-margin - $default-margin;
        overflow: hidden;
        background-color: transparent;
        z-index: 9;
    }

    &--taggbox + &--usps {
        margin-top: -$default-block-margin - $default-margin;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 100%;
            width: 48%;
            max-width: 100%;
            height: 1045px;
            clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
            background-color: $color-black;

            @include media-breakpoint-down(sm) {
                width: 85%;
            }
        }
    }
}

.taggbox {
    margin-bottom: $default-margin;

    &__title {
        white-space: nowrap;

        .is-h2 {
            font-size: 152px;
            line-height: normal;
            font-weight: 600;
            color: $color-black;

            @include media-breakpoint-down(lg) {
                font-size: 100px;
            }

            @include media-breakpoint-down(md) {
                font-size: 82px;
            }
        }
    }
}
