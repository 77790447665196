.swiper {
    margin: 0;
    overflow: hidden;

    .swiper-slide {
        height: auto;
    }

    .swiper-navigation {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0;

        .swiper-button-next,
        .swiper-button-prev {
            @include icon(arrow, $text-color, 10px, 20px);
            display: block;
            width: 40px;
            height: 40px;
            cursor: pointer;
            margin: 0 0 $default-margin;

            &.swiper-button-disabled {
                cursor: default;
                opacity: .5;
            }
        }

        .swiper-button-prev {
            transform: rotate(-180deg);
        }
    }

    .swiper-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0;

        .swiper-pagination-bullet {
            flex: 0 0 12px;
            width: 12px;
            height: 12px;
            border: 2px solid $color-white;
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 4px $default-margin;
            outline: none;

            &:hover,
            &:active {
                outline: none;
            }

            &.swiper-pagination-bullet-active {
                background-color: $color-white;
            }
        }
    }
}
