// Color Guid block

.block {
    &--color-guide {
        margin: 0;
    }
}

.color-guide {
    display: flex;
    flex-direction: column;
    margin: 0 auto $default-block-margin - $default-margin;

    &__title {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(sm) {
            align-items: normal;
            flex-direction: column;
        }

        .is-h2 {
            flex: 1;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .form {
            flex: 0 0 325px;
            max-width: 325px;
            padding: 0 0 0 30px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
        }
    }

    &__content {
        .richtext {
            p {
                font-size: 24px;
            }
        }
    }

    &__buttons {
        text-align: center;
    }
}

.color-guide-overview {
    $self: &;
    @include make-row();
    margin: 0 -8px ($default-margin - 20px);

    &__item {
        @include make-col(4);
        padding: 0 8px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        &:hover,
        &:focus {
            color: $white;
        }

        .color-guide-color {
            margin: 0 0 20px;
            height: calc(100% - 20px);
        }
    }
}

.color-guide-color {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;
    padding: 0 0 0 55%;

    &__content {
        display: flex;
        flex-direction: column;
        padding: 15px 15px 0;
        background-color: $color-black;
        height: 100%;
        min-height: 100px;

        .is-h3,
        .is-h4 {
            color: $color-white;
            margin: 0 0 15px;

            + .is-h3,
            + .is-h4 {
                margin-top: -15px;
            }
        }

        .is-h3 {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
        }

        .is-h4 {
            font-size: 16px;
            line-height: 16px;
        }
    }
}

.color-guide-table {
    display: block;
    background-color: $color-black;
    margin-bottom: $default-block-margin;
    padding: 20px;

    @include media-breakpoint-down(md) {
        display: flex;
        padding: 15px 10px 5px;
        flex-wrap: wrap;
    }

    + .pagination {
        margin-bottom: $default-margin - 5px;
    }

    &__row {
        position: relative;
        display: grid;
        grid-template-columns: 80px minmax(117px, 10.5%) minmax(224px, 22.5%) minmax(120px, 11.5%) minmax(120px, 16%) minmax(100px, 10%) 1fr 1fr;
        grid-template-rows: 100% auto;

        &.cz {
            grid-template-columns: 80px minmax(117px, 10.5%) minmax(224px, 22.5%) minmax(90px, 9%) minmax(120px, 16%) minmax(100px, 10%) 1fr 1fr 1fr;
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: 80px minmax(100px, 11%) minmax(124px, 16%) minmax(120px, 11.5%) minmax(120px, 17%) minmax(100px, 10%) 1fr 1fr;

            &.cz {
                grid-template-columns: 80px minmax(100px, 11%) minmax(110px, 16%) minmax(100px, 11.5%) minmax(100px, 17%) minmax(100px, 10%) 1fr 1fr 1fr;
            }
        }

        @include media-breakpoint-down(md) {
            @include make-col(6);
            max-width: calc(50% - 20px);
            display: block;
            border-bottom: 2px solid $color-black-bg;
            margin: 0 10px 20px;
            padding: 0 10px 10px;
        }

        @include media-breakpoint-down(xs) {
            @include make-col(12);
            padding: 0 4px 10px;
            margin: 0 0 10px;
        }

        &:first-of-type:not([href]) {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &::before {
            content: '';
            width: calc(100% + 20px);
            position: absolute;
            left: -10px;
            top: 0;
            height: 100%;
            background-color: $color-white;
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        .color-guide-row {
            &:not(.color-guide-row--head) {
                color: $color-white;
            }

            a {
                color: $color-white;
            }
        }

        &[href]:hover,
        &[href]:focus {
            text-decoration: none;

            &::before {
                opacity: 1;
            }

            .color-guide-row {
                border-color: $color-white;
                color: $color-black;
            }

            .icon {
                &--check-alt {
                    @include icon(check-alt, $color-black, 14px, 10px);
                }

                &--cross {
                    @include icon(close, $color-black, 13px, 13px);
                }
            }
        }
    }
}

.color-guide-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 8px;
    border-left: 2px solid $color-black-bg;
    border-bottom: 2px solid $color-black-bg;
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all .3s ease-in-out;

    @include media-breakpoint-down(lg) {
        padding: 8px 6px;
    }

    @include media-breakpoint-down(md) {
        border: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 100% auto;
    }

    &::before {
        content: attr(data-label);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: $color-gray-darker;
        display: none;
        padding-right: 10px;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &:first-of-type {
        border-left: 0;

        &::before {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &--head {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: $color-gray-darker;
        display: block;

        &:nth-of-type(4) {
            padding-right: 0;
        }
    }

    span {
        word-wrap: break-word;
    }

    &--center {
        span {
            display: block;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }
    }

    &__bg {
        width: 58px;
        height: 54px;
        display: block;

        @include media-breakpoint-down(md) {
            width: 100%;
            height: 60px;
            grid-column: 1/3;
        }
    }

    .info-icon {
        @include icon(question, $color-black, 10px, 10px);
        display: inline-block;
        position: absolute;
        top: -7px;
        right: 8px;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        font-size: 0;
        background-color: $color-white;
        border-radius: 50%;

        @include media-breakpoint-down(lg) {
            top: inherit;
            bottom: 4px;
            width: 18px;
            height: 18px;
        }

        @include media-breakpoint-only(lg) {
            top: 6px;
        }

        &:hover {
            .tooltip {
                visibility: visible;
            }
        }

        &.info-icon {
            &--mobile {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    top: 5px;
                    right: auto;
                    left: calc(50% - 38px);
                }
            }
        }

        &.inline {
            top: 16px;
            right: 14px;
        }
    }

    .tooltip {
        $self: &;
        cursor: pointer;
        position: relative;
        display: inline-block;
        z-index: 9;
        width: 22px;
        min-height: 25px;
        visibility: hidden;

        @include media-breakpoint-down(lg) {
            width: 18px;
            min-height: 18px;
        }

        &__text {
            width: 20vw;
            min-width: 320px;
            background-color: $color-black-bg;
            color: $color-white;
            text-align: left;
            padding: 20px;
            top: 33px;
            font-size: 15px;
            text-transform: none;
            line-height: 20px;
            font-weight: 400;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                top: -9px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $color-black-bg;
            }

            p {
                color: $color-white;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.filter {
    .wrapper-color-code {
        display: flex;

        > div {
            flex-grow: 1;
            margin: 0 10px 0 0;
        }

        .tooltip {
            min-width: 22px;
            position: relative;
            top: auto;
            right: auto;
            left: auto;
            margin: 11px 0 0 auto;

            @include media-breakpoint-down(lg) {
                margin-right: 7px;
            }
        }
    }
}
