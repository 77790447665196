// Colors Guide block

.block {
    &--colors-guide {
        margin: 0 0 $default-block-margin - $default-margin;

        .wrapper {
            @include media-breakpoint-up(lg) {
                width: 1440px;
                max-width: 100%;
            }
        }
    }
}

.colors-guide {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;

        .is-h2 {
            + .is-h4 {
                font-size: 24px;
                line-height: 34px;
                font-weight: 400;
                margin: -$default-margin 0 $default-margin;
            }
        }
    }

    &__slider {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.colors-guide-overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 -25px;

    @include media-breakpoint-down(md) {
        margin: 0 -15px;
    }

    &__item {
        @include make-col(2.4);
        padding: 0 25px;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(lg) {
            @include make-col(3);
        }

        @include media-breakpoint-down(md) {
            @include make-col(4);
            padding: 0 15px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(6);
        }

        @include media-breakpoint-down(xs) {
            @include make-col(12);
        }

        .colors-guide-color {
            height: 100%;
            margin: 0;
        }
    }

    + .richtext {
        text-align: center;
        font-style: italic;
    }
}

.colors-guide-color {
    display: flex;
    flex-direction: column;
    padding: 140px 0 0;
    margin: 0 0 $default-margin;

    &__content {
        flex: 1;
        color: $color-black;
        font-weight: 600;
        padding: 20px 20px 0;
        background: $color-white;

        .is-h3 {
            color: $color-black;
            font-size: $text-size;
            text-transform: none;
            line-height: 22px;

            + p {
                margin-top: -$default-margin;
            }
        }

        p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            margin: 0 0 20px;
        }
    }
}
