// hero-main block

.block {
    &--hero-main {
        margin: 0 0 $default-block-margin;

        &:first-child {
            margin-top: -$default-margin;
        }
    }

    &--hero-main + &--color-blue,
    &--hero-main + &--color-blue-dark,
    &--hero-main + &--color-black,
    &--hero-main + &--testimonial {
        margin-top: -$default-block-margin;
    }
}

.hero-main {
    $self: &;
    position: relative;

    @include media-breakpoint-down(sm) {
        height: 700px;
    }

    .btn {
        &--scroll {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            z-index: 1;
        }
    }

    &__image {
        height: 100%;

        picture {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 760px;
        height: 100%;
        padding: 0 30px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            max-width: 720px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 540px;
        }

        .is-h1 {
            font-size: 96px;
            line-height: 96px;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 46px;
                line-height: 56px;
            }
        }
    }

    &--white-shadow {
        #{ $self }__content {
            .is-h1 {
                text-shadow: 0 0 10px rgba($color-black, .5);
            }
        }
    }

    &--red {
        #{ $self }__content {
            .is-h1 {
                color: $color-red;
            }
        }
    }

    &--green {
        #{ $self }__content {
            .is-h1 {
                color: $color-green;
            }
        }
    }

    &--orange {
        #{ $self }__content {
            .is-h1 {
                color: $color-orange;
            }
        }
    }

    &--blue {
        #{ $self }__content {
            .is-h1 {
                color: $color-blue-light;
            }
        }
    }

    &--yellow {
        #{ $self }__content {
            .is-h1 {
                color: $color-yellow;
            }
        }
    }
}

.search-products {
    $self: &;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;

    &__content {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        .is-h3 {
            font-size: 14px;
            line-height: 14px;
        }
    }

    &__options {
        position: relative;
        z-index: 1;

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -8px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            li {
                margin: 0 8px 10px;

                button,
                .btn {
                    position: relative;
                    display: block;
                    width: 100%;
                    text-align: left;
                    color: $color-white;
                    font-size: 14px;
                    line-height: 28px;
                    font-weight: 600;
                    text-transform: uppercase;
                    padding: 15px 15px 15px 66px;
                    background: rgba($color-black, .5);
                    border: 0;
                    border-bottom: 2px solid $color-white;

                    &::after {
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        display: block;
                        width: 36px;
                        height: 36px;
                        margin: -18px 0 0;
                        content: '';
                    }

                    &:hover,
                    &:focus {
                        color: $color-black;
                        background: $color-white;
                    }
                }

                &.search {
                    button {
                        &::after {
                            @include icon(search, $color-white, 20px, 20px);
                        }

                        &:hover,
                        &:focus {
                            &::after {
                                @include icon(search, $color-black, 20px, 20px);
                            }
                        }
                    }
                }

                &.marker {
                    button,
                    .btn {
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;

                        &::after {
                            @include icon(marker, $color-white, 30px, 30px);
                        }

                        &:hover,
                        &:focus {
                            &::after {
                                @include icon(marker, $color-black, 30px, 30px);
                            }
                        }
                    }
                }

                &.paint-pallet {
                    a,
                    button {
                        &::after {
                            @include icon(paint-pallet, $color-white, 36px, 36px);
                        }

                        &:hover,
                        &:focus {
                            &::after {
                                @include icon(paint-pallet, $color-black, 36px, 36px);
                            }
                        }
                    }
                }
            }
        }
    }

    &__switch {
        display: none;
        padding: 20px 0 0;
        z-index: 1;

        button {
            width: 100%;
            color: $color-white;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 0;
        }
    }

    &.active {
        #{ $self }__options {
            ul {
                li {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }

                    &.active {
                        @include media-breakpoint-down(sm) {
                            display: block;
                        }
                    }
                }
            }
        }

        #{ $self }__switch {
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
    }

    &__search-boxes {
        position: relative;
        height: 60px;
        z-index: 0;

        &::after {
            position: absolute;
            top: 60px;
            width: 100%;
            height: 140px;
            content: '';
        }

        .search-box {
            $self: &;
            position: absolute;
            left: 0;
            bottom: -70px;
            display: flex;
            flex-flow: column;
            width: 100%;
            transition: all ease-in-out .3s;
            margin: 0;
            opacity: 0;

            &.active {
                bottom: 0;
                opacity: 1;

                @include media-breakpoint-down(sm) {
                    bottom: inherit;
                    top: 0;
                }
            }

            .info-toggle {
                position: absolute;
                top: 15px;
                right: 125px;

                @include media-breakpoint-down(sm) {
                    right: 60px;
                }
            }

            .form {
                display: flex;
                flex-flow: wrap row;
                margin: 0;
            }

            &__form {
                opacity: 0;
                position: absolute;
                width: 100%;
                bottom: -70px;
                left: 0;
                transition: all ease-in-out .3s;

                &.active {
                    bottom: 0;
                    opacity: 1;
                }
            }

            &__options {
                position: relative;
                bottom: 0;
                left: 0;
                width: 100%;
                opacity: 1;
                transition: all ease-in-out .3s;

                &.active {
                    opacity: 0;
                    bottom: -70px;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0 -6px;

                    li {
                        flex: 0 0 50%;
                        max-width: 50%;
                        padding: 0 6px;

                        button {
                            width: 100%;
                            color: $color-white;
                            font-size: 11px;
                            font-weight: 600;
                            text-transform: uppercase;
                            padding: 13px 3px;
                            background: rgba($color-black, .5);
                            border: 2px solid $color-white;

                            &:hover,
                            &:focus {
                                color: $color-black;
                                background-color: $color-white;
                            }
                        }
                    }
                }
            }

            form {
                .form-group {
                    flex: 1;
                    margin: 0;

                    &:last-child {
                        flex: 0;
                    }

                    .tooltip {
                        @include icon(question, $color-black, 14px, 14px);
                        right: 10px;
                        width: 28px;
                        height: 28px;
                        margin: -14px 0 0;
                        background-color: $color-white;
                    }
                }

                .form-control {
                    &--select {
                        margin-right: 10px;
                    }
                }

                .btn {
                    width: 112px;
                    padding: 14px 25px;
                    color: $color-black;
                    background: $color-white;
                    border-color: $color-white;

                    @include media-breakpoint-down(sm) {
                        flex: 0 0 50px;
                        max-width: 50px;
                        padding: 14px 0;
                    }
                }

                .form-element-suggestions {
                    &__content {
                        margin: 0;
                        background-color: rgba($color-black, .5);

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
