// Products block

.block {
    &--products {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.products {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__slider {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.product {
    $self: &;
    display: flex;
    flex-direction: column;

    &__image {
        margin: 0 0 $default-margin;

        picture {
            display: flex;
            justify-content: center;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                max-height: 418px;
            }

            img {
                max-height: 418px;
                
                @include media-breakpoint-down(sm) {
                    width: auto;
                    height: 100%;
                   
                }

                @include media-breakpoint-down(xs) {
                    object-fit: contain;
                }
            }
        }
    }

    &__content {
        .is-h4 {
            color: $color-white;
            text-align: center;
        }
    }

    &[href] {
        &:hover,
        &:focus {
            text-decoration: none;

            #{ $self }__content {
                .is-h4 {
                    color: $color-red;
                }
            }
        }
    }
}

.product-summary {
    @include make-row();
    margin: 0 -15px;

    &__image {
        @include make-col(6);
        padding: 0 15px;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(md) {
            @include make-col(12);

            img {
                max-height: 350px;
            }
        }

        picture {
            display: flex;
            justify-content: center;
        }
    }

    &__content {
        @include make-col(6);
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .is-h2,
        .richtext {
            padding-right: 60px;

            @include media-breakpoint-down(md) {
                padding-right: 0;
            }
        }
    }

    &__item {
        max-width: 450px;
    }
}

.products-slider {
    display: flex;
    flex-direction: row;

    &__item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 20px;

        @include media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }
}

.swiper {
    &--products-slider {
        overflow: initial;
    }
}
