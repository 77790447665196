button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.btn {
    $self: &;
    position: relative;
    display: inline-block;
    font-family: $text-font;
    color: $color-white;
    font-size: 16px;
    line-height: 28px;
    padding: 14px 75px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    border: 2px solid $color-white;
    background-color: transparent;
    border-radius: 0;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
        padding: 15px 40px;
    }

    @include media-breakpoint-down(md) {
        padding: 15px 20px;
    }

    @include media-breakpoint-down(sm) {
        padding: 15px;
    }

    + .btn {
        margin-left: 15px;
    }

    &:hover,
    &:focus {
        color: $color-black-bg;
        text-decoration: none;
        border-color: $color-white;
        background-color: $color-white;
    }

    &--secondary {
        background: $color-red;
        border: 1px solid $color-red;
        color: $color-white;
        font-size: 21px;
        padding: 15px 28px;
    }

    &--close {
        display: flex;
        align-items: center;
        color: $color-white;
        border: 0;
        background: transparent;
        padding: 0;

        &::after {
            @include icon(close, $color-white, 20px, 20px);
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            margin-top: -2px;
            content: '';
        }

        &:hover,
        &:focus {
            color: $color-red;
            background: transparent;

            &::after {
                @include icon(close, $color-red, 20px, 20px);
            }
        }
    }

    &--white {
        color: $color-black;
        padding: 8px 10px;
        background-color: $color-white;
        border-color: $color-white;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-black;
            border-color: $color-black;
        }
    }

    &--black {
        color: $color-white;
        background-color: $color-black;
        border: 0;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-red;
        }
    }

    &--small {
        padding: 6px 10px;
    }

    &--outlined {
        padding: 8px 10px;
        background: rgba($color-black, .5);
        border: 2 solid $color-white;
        font-size: 14px;
        font-weight: 600;
    }

    &--scroll {
        border: 0;
        padding: 0;
        background: transparent;
        text-align: center;
        font-size: 10px;

        &::after {
            @include icon(scroll, $color-white, 12px, 40px);
            content: '';
            width: 12px;
            height: 40px;
            display: block;
            margin: 0 auto;
        }

        &:hover,
        &:focus {
            background: none;
            color: $color-black;

            &::after {
                @include icon(scroll, $color-black, 12px, 40px);
            }
        }
    }

    &--link {
        color: $color-white;
        font-size: $text-size;
        line-height: $line-height;
        font-weight: 400;
        padding: 0;
        border: 0;
        background-color: transparent;
        text-transform: none;

        &::after {
            @include icon(arrow, $color-white, 6px, 9px);
            content: '';
            width: 6px;
            height: 9px;
            display: inline-block;
            margin-left: 12px;
        }

        &:hover,
        &:focus {
            color: $color-red;
            background-color: transparent;

            &::after {
                @include icon(arrow, $color-red, 6px, 9px);
            }
        }
    }

    &--social-share {
        display: flex;
        align-items: center;
        color: $color-white;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        padding: 5px 0;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;

        &::before {
            @include icon(share, $color-white, 16px, 20px);
            display: inline-block;
            width: 16px;
            height: 20px;
            margin-right: 10px;
            content: '';
        }

        &:hover,
        &:focus {
            color: $color-red;
            text-decoration: none;
            background-color: transparent;

            &::before {
                @include icon(share, $color-red, 16px, 20px);
            }
        }
    }
}

.btn-dropdown {
    position: relative;

    .btn {
        &::after {
            @include icon(arrow, $color-white, 10px, 15px);
            position: absolute;
            right: 20px;
            top: 50%;
            width: 10px;
            height: 15px;
            content: '';
            margin: -7px 0 0;
            transform: rotate(90deg);
        }
    }

    ul {
        position: absolute;
        left: -99999px;
        top: 100%;
        max-height: 340px;
        overflow: auto;
        padding: 10px;
        background: $color-black;
        border: 2px solid;
        border-top: 0 solid;
        width: 100%;

        li {
            a {
                display: block;
                color: $color-white;
                line-height: normal;

                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }
    }

    &:hover,
    &:focus {
        .btn {
            &::after {
                @include icon(arrow, $color-black, 10px, 15px);
            }
        }

        ul {
            left: 0;
        }
    }
}
