// Colors Guide block

.colors-guide-color {
    &__content {
        ::-moz-selection {
            background-color: $color-black-bg;
            color: $color-white;
        }

        ::selection {
            background-color: $color-black-bg;
            color: $color-white;
        }
    }
}
