.navigation {
    $self: &;

    ul {
        margin: 0;

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $text-color;
                padding: 6px 5px;

                @include media-breakpoint-down(md) {
                    padding: 6px 0;
                }
            }

            > ul {
                display: none;
            }

            &:hover,
            &:focus {
                > a {
                    color: $color-red;
                    text-decoration: none;
                }
            }

            &.active {
                > a {
                    color: $color-red;
                }

                > ul {
                    display: block;
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrow, $text-color, 15px, 14px);
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: none;
                    width: 44px;
                    height: 44px;
                    padding: 0;
                    transform: rotate(90deg);
                    transition: all .3s;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }

                &:hover,
                &:focus {
                    > .toggle-subnav {
                        @include icon(arrow, $color-red, 15px, 14px);
                    }
                }

                &.active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-red, 15px, 14px);
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        transform: rotate(-90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-red, 14px, 14px);
                        transform: rotate(90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
                // scss-lint:enable MergeableSelector
            }
        }
    }

    &--header {
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            position: fixed;
            top: 0;
            left: 100%;
            bottom: 0;
            width: 100%;
            height: 100vh;
            padding: 60px 0 15px;
            margin: 0;
            overflow: auto;
            background-color: $color-black;
            transition: all .3s ease-in-out;
        }

        .search-toggle,
        .language-toggle,
        .contact-toggle,
        .downloads-toggle {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        #{ $self }__mobile {
            display: none;

            @include media-breakpoint-down(md) {
                position: absolute;
                min-height: 50px;
                display: flex;
                right: 0;
                top: 5px;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            @include media-breakpoint-down(md) {
                display: block;
                padding: 0 15px;
                text-align: left;
            }

            li {
                margin: 0 5px;

                @include media-breakpoint-down(md) {
                    display: block;
                    margin: 0;
                }

                a {
                    color: $text-color;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    text-transform: uppercase;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 32px;
                    }
                }

                > ul {
                    position: absolute;
                    left: -99999px;
                    display: block;
                    width: 200px;
                    padding: 0 5px;
                    background-color: $color-white;

                    @include media-breakpoint-down(md) {
                        position: static;
                        display: none;
                        width: auto;
                        margin: 0;
                        padding: 0 0 0 20px;
                        background-color: transparent;
                    }

                    li {
                        display: block;
                        margin: 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-red;
                    }

                    > ul {
                        left: auto;
                    }
                }

                &.has-subnav {
                    position: static;

                    @include media-breakpoint-down(md) {
                        position: relative;
                    }

                    .navigation {
                        left: -99999px;

                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus {
                        > a {
                            position: relative;

                            &::before {
                                position: absolute;
                                top: 0;
                                display: block;
                                width: 100%;
                                height: 50px;
                                content: '';

                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
                            }

                            &::after {
                                content: '';
                                width: 0;
                                height: 0;
                                border-left: 30px solid transparent;
                                border-right: 30px solid transparent;
                                border-top: 20px solid $color-black-bg;
                                position: absolute;
                                bottom: -30px;
                                left: 50%;
                                transform: translateX(-50%);
                                z-index: 9;

                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
                            }
                        }

                        .navigation {
                            left: 0;
                            display: flex;

                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }

                    &.active,
                    &.subnav-active {
                        > a {
                            &::after {
                                @include icon(close, $text-color, 8px, 8px);
                            }
                        }

                        .navigation {
                            @include media-breakpoint-down(md) {
                                display: block;
                            }
                        }
                    }

                    &.active.subnav-active {
                        .navigation {
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        > ul {
            > li {
                &.has-subnav {
                    > a {
                        padding-right: 5px;

                        @include media-breakpoint-down(md) {
                            padding-right: 44px;
                        }
                    }

                    > .toggle-subnav {
                        display: none;

                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &--subnav {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        margin: 0;
        background-color: $color-black;

        @include media-breakpoint-down(md) {
            position: static;
            background-color: transparent;
        }

        #{ $self }__content {
            flex: 1;
            max-width: 1170px;
            padding: 40px 0 0 60px;
            margin: 0 -10% 0 0;

            @include media-breakpoint-down(lg) {
                padding: 40px 0 0 20px;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
                max-width: unset;
                margin: 0;
            }
        }

        #{ $self }__highlight {
            flex: 0 0 645px;
            max-width: 645px;
            background: $color-white;
            position: relative;
            padding: 70px 0 24px;

            @include media-breakpoint-down(lg) {
                flex: 0 0 475px;
                max-width: 475px;
                padding: 30px 0;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &::after {
                content: '';
                transform: skew(-32deg);
                width: 60%;
                height: 100%;
                background: $color-black;
                position: absolute;
                left: -43%;
                bottom: 0;

                @include media-breakpoint-down(lg) {
                    left: -39%;
                }
            }
        }

        &::before {
            position: absolute;
            top: -15px;
            left: 0;
            display: block;
            width: 100%;
            height: 15px;
            content: '';

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        ul {
            padding: 0;
            display: block;
            column-count: 3;
            column-gap: 30px;
            position: relative;
            z-index: 9;
            margin: 0 -10px;

            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0 0 0 20px;
                column-count: unset;
            }

            li {
                position: relative;
                width: 100%;
                padding: 0 10px;
                margin: 0;

                @include media-breakpoint-down(md) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }

                a {
                    display: block;
                    color: $color-white;
                    font-size: 16px;
                    line-height: 32px;
                    text-transform: none;
                    font-weight: 400;
                    padding: 0;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 32px;
                        padding: 3px 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-red;
                    }
                }

                &.has-subnav {
                    position: relative;

                    > a {
                        font-weight: 400;
                    }

                    > .toggle-subnav {
                        display: none;

                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                &.active {
                    > ul {
                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                ul {
                    display: block;
                    position: static;
                    width: 100%;
                    padding: 0;
                    margin: 0 0 10px;

                    @include media-breakpoint-down(md) {
                        display: none;
                        padding: 0 0 0 20px;
                        margin: 0;
                    }

                    li {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0;

                        a {
                            font-weight: 400;
                        }

                        &.has-subnav {
                            > a {
                                font-weight: 400;
                            }

                            > .toggle-subnav {
                                display: block;
                            }
                        }

                        &.active {
                            > ul {
                                @include media-breakpoint-down(md) {
                                    display: block;
                                }
                            }
                        }

                        ul {
                            display: none;
                            padding: 0 0 0 20px;
                            list-style-type: disc;
                            margin: 0;

                            li {
                                display: list-item;
                            }

                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &--footer {
        ul {
            display: flex;
            flex-wrap: wrap;

            @include media-breakpoint-down(md) {
                justify-content: center;
                margin: 0 0 50px;
            }

            li {
                display: inline-block;
                margin: 0 10px;

                a {
                    color: $text-color;
                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    padding: 0;
                }
            }
        }
    }

    &--anchor {
        margin: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -5px;

            li {
                display: inline-block;
                margin: 0 30px;

                a {
                    color: $color-white;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    text-transform: none;
                    text-decoration: none;
                    position: relative;
                    padding: 8px 10px 18px;

                    &::before {
                        content: '';
                        width: 0;
                        height: 4px;
                        background: $color-white;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transition: all ease .3s;
                        opacity: 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-white;
                        text-decoration: none;

                        &::before {
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }

                &.active {
                    > a {
                        color: $color-white;

                        &::before {
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                left: 0;
            }
        }
    }
}

.primary-search-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                top: 79px;
            }
        }
    }
}
