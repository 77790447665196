@font-face {
    font-family: 'geomanist';
    src: url('/assets/fonts/geomanist-regular-webfont.eot');
    src: url('/assets/fonts/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/geomanist-regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-regular-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-regular-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-regular-webfont.svg#geomanistregular') format('svg');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'geomanist';
    src: url('/assets/fonts/geomanist-bold-webfont.eot');
    src: url('/assets/fonts/geomanist-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/geomanist-bold-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-bold-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-bold-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-bold-webfont.svg#geomanistbold') format('svg');
    font-display: swap;
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'geomanist';
    src: url('/assets/fonts/geomanist-medium-webfont.eot');
    src: url('/assets/fonts/geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/geomanist-medium-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-medium-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-medium-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-medium-webfont.svg#geomanistregular') format('svg');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'geomanist';
    src: url('/assets/fonts/geomanist-light-webfont.eot');
    src: url('/assets/fonts/geomanist-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/geomanist-light-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-light-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-light-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-light-webfont.svg#geomanistregular') format('svg');
    font-display: swap;
    font-weight: 300;
    font-style: normal;
}
