// Spotler block

.block {
    &--spotler {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.spotler {
    $self: &;
    @include make-row();
    justify-content: center;

    &__title {
        @include make-col(12);
        padding: 0 15px;
        order: 1;

        .is-h3,
        .is-h2 {
            text-align: center;
        }
    }

    &__form {
        @include make-col(6);
        padding: 0 15px;
        order: 2;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            width: 100%;
        }
    }

    &__content {
        @include make-col(6);
        padding: 0 20px 0 15px;
        order: 3;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            order: 2;
            padding: 0 15px;
        }
    }

    &--right {
        #{ $self }__form {
            order: 3;
        }

        #{ $self }__content {
            order: 2;
        }
    }

    &--center {
        #{ $self }__form {
            @include make-col(12);
            order: 3;
        }

        #{ $self }__content {
            @include make-col(12);
            order: 2;
        }
    }
}
