.block {
    &--office {
        margin: 0 0 $default-block-margin - $default-margin;
    }
}

.office {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}
