// Hero block

.block {
    &--hero {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.hero {
    $self: &;
    position: relative;
    display: block;
    margin: 0 0 $default-margin;

    &__image {
        overflow: hidden;
        position: relative;
        height: 100%;

        &::after {
            position: absolute;
            right: -30%;
            bottom: 0;
            display: block;
            width: 60%;
            height: 100%;
            background: $color-red;
            content: '';
            transform: skew(-32deg, 0);
        }

        picture {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &.is-video {
            &::before {
                margin-top: 60px;

                @include media-breakpoint-down(md) {
                    margin-top: 30px;
                }
            }
        }
    }

    &__content {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);

        .is-h1 {
            font-size: 96px;
            line-height: 96px;
            margin: 0;

            @include media-breakpoint-down(md) {
                font-size: 46px;
                line-height: 46px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 36px;
                line-height: 36px;
            }
        }
    }

    &--green {
        #{ $self }__image {
            &.is-video {
                &:hover,
                &:focus {
                    &::before {
                        @include icon(play, $color-green, 50px, 50px);
                    }
                }
            }

            &::after {
                background: $color-green;
            }
        }
    }

    &--blue {
        #{ $self }__image {
            &.is-video {
                &:hover,
                &:focus {
                    &::before {
                        @include icon(play, $color-blue-light, 50px, 50px);
                    }
                }
            }

            &::after {
                background: $color-blue-light;
            }
        }
    }

    &--yellow {
        #{ $self }__image {
            &.is-video {
                &:hover,
                &:focus {
                    &::before {
                        @include icon(play, $color-yellow, 50px, 50px);
                    }
                }
            }

            &::after {
                background: $color-yellow;
            }
        }
    }

    &--orange {
        #{ $self }__image {
            &.is-video {
                &:hover,
                &:focus {
                    &::before {
                        @include icon(play, $color-orange, 50px, 50px);
                    }
                }
            }

            &::after {
                background: $color-orange;
            }
        }
    }

    &--simple {
        #{ $self }__image {
            &::after {
                display: none;
            }
        }
    }
}
