.block {
    &--car-color-finder {
        .article {
            @include media-breakpoint-down(md) {
                margin-left: -30px;
                margin-right: -30px;
            }

            &__title {
                width: 54%;

                @include media-breakpoint-down(lg) {
                    width: 75%;
                }

                @include media-breakpoint-down(md) {
                    @include make-col(12);
                    width: 100%;
                }
            }

            &__content {
                width: 54%;

                @include media-breakpoint-down(lg) {
                    width: 75%;
                }

                @include media-breakpoint-down(md) {
                    @include make-col(12);
                    width: 100%;
                }
            }

            &__extra {
                width: 54%;

                @include media-breakpoint-down(lg) {
                    width: 75%;
                }

                @include media-breakpoint-down(md) {
                    @include make-col(12);
                    width: 100%;
                }
            }
        }
    }
}

.car-color-finder {
    &__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: $default-block-margin;

        @include media-breakpoint-down(sm) {
            justify-content: center;
        }

        .form {
            flex: 0 0 30%;
            max-width: 30%;
            margin: 0 0 $default-margin auto;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                display: flex;
                align-items: center;
            }

            .form-control {
                font-weight: 700;
                text-transform: uppercase;
                padding-left: 20px;
            }
        }
    }

    &__image {
        position: relative;
        margin-bottom: $default-block-margin;
        text-align: center;
    }

    &__points {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .point-item {
            position: absolute;
            background: $color-red;
            width: 13px;
            height: 13px;
            border-radius: 50%;

            &__wrap {
                position: relative;
                display: block;
            }

            span {
                display: none;
                opacity: 0;
                position: absolute;
                left: 0;
                bottom: 140px;
                padding-left: 20px;
                white-space: nowrap;
                transition: opacity .3s ease-in-out;
                font-size: 12px;
                line-height: 14px;

                &::before {
                    content: '';
                    width: 1px;
                    height: calc(100% + 140px);
                    position: absolute;
                    left: 6px;
                    top: 0;
                    background: $color-red;
                }
            }

            &.active {
                span {
                    display: block;
                    opacity: 1;
                }
            }

            &--small {
                span {
                    bottom: 100px;

                    &::before {
                        height: calc(100% + 100px);
                    }
                }
            }

            &--left {
                span {
                    padding-left: 0;
                    padding-right: 20px;
                    left: initial;
                    right: 6px;

                    &::before {
                        left: inherit;
                        right: 0;
                    }
                }
            }
        }
    }

    &__content {
        .is-h5 {
            + .richtext {
                margin-top: -$default-margin / 2;
            }
        }
    }
}
